<template>
    <div class="Home">
      <page-header/>

      <section class="uk-section uk-section-smal0l uk-padding-remove">
        <div class="uk-container">
          <div class="uk-grid-medium uk-child-width-1-1" uk-grid>
            <div class="uk-text-center">
              <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
                <li>
                  <a>{{$t("orgaExam.cart.home")}}</a>
                </li>
                <li>
                  <span>{{$t("orgaExam.cart.cart")}}</span>
                </li>
              </ul>
              <h1 class="uk-margin-small-top uk-margin-remove-bottom">{{$t("orgaExam.cart.cart")}}</h1>
            </div>
            <div>
              <div class="uk-grid-medium" uk-grid>
                <div class="uk-width-1-1 uk-width-expand@m">
                  <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                    <header
                      class="uk-card-header uk-text-uppercase uk-text-muted uk-text-center uk-text-small uk-visible@m"
                    >
                      <div class="uk-grid-small uk-child-width-1-2" uk-grid>
                        <div>{{$t("orgaExam.cart.product")}}</div>
                        <div>
                          <div class="uk-grid-small uk-child-width-expand" uk-grid>
                            <div>{{$t("orgaExam.cart.point")}}</div>
                            <div  class="tm-quantity-column">quantity</div>
                            <div >sum</div>
                            <div class="uk-width-auto">
                              <div style="width: 20px;"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>
                    <div v-if="cartItemList.length > 0">
                      <app-cart-item
                        v-for="cartItem in cartItemList"
                        :cartItem="cartItem"
                        :key="cartItem.id"
                      ></app-cart-item>
                    </div>

                    <div v-else>
                      <div class="uk-alert-primary" uk-alert>
                        <a class="uk-alert-close" uk-close></a>
                        <p class="uk-text-center">{{$t("orgaExam.cart.empty")}}</p>
                      </div>
                    </div>
                    <div  class="uk-card-footer">
                      <label>
                        <span class="uk-form-label uk-margin-small-right">{{$t("orgaExam.cart.pCode")}}
                         </span>
                        <div class="uk-inline">
                          <a class="uk-form-icon uk-form-icon-flip" href="#" uk-icon="arrow-right"></a>
                          <input class="uk-input uk-form-width-small" type="text"/>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="uk-width-1-1 tm-aside-column uk-width-1-4@m">
                  <div
                    class="uk-card uk-card-default uk-card-small tm-ignore-container"
                    uk-sticky="offset: 30; bottom: true; media: @m;"
                  >
                    <div class="uk-card-body">
                      <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand uk-text-muted">
                          {{$t("orgaExam.cart.subTotal")}}
                        </div>
<!--                        <div>{{ cartValue|currency }}</div>-->
                        <div>{{ cartValue }}</div>

                      </div>
                      <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-expand uk-text-muted"> {{$t("orgaExam.cart.disCount")}}</div>
<!--                        <div class="uk-text-danger">- {{cartValue-cartValueDiscount|currency}} Point</div>-->
                        <div class="uk-text-danger">- {{cartValue-cartValueDiscount}} Point</div>

                      </div>
                    </div>
                    <div class="uk-card-body">
                      <div class="uk-grid-small uk-flex-middle" uk-grid>
                        <div class="uk-width-expand uk-text-muted">
                           {{$t("orgaExam.cart.toPoint")}}
                        </div>
                        <div class="uk-text-lead uk-text-bolder">{{ cartValueDiscount}}</div>
<!--                        <div class="uk-text-lead uk-text-bolder">{{ cartValueDiscount|currency}}</div>-->
                      </div>
<!--                      <a-->
<!--                        @click="checkout"-->
<!--                        class="uk-button uk-button-primary uk-margin-small uk-width-1-1"-->
<!--                        href="#"-->
<!--                      > {{$t("orgaExam.cart.check")}}</a>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <page-footer/>
    </div>

</template>


<script>
  import {mapActions, mapGetters} from 'vuex'
  import CartItem from './Cart/CheckCartItem.vue'
  import CheckoutService from '@/services/CheckoutService'
  import UIkit from 'uikit'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
	const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  export default {
    computed: {
      ...mapGetters([
        'cartItemList',
        'isLoggedIn',
        'currentUser',
        'cartValue',
        'cartValueDiscount'
      ])
    },
    components: {
      appCartItem: CartItem,
      PageHeader,
      PageFooter

    },
    methods: {
      ...mapActions(['addMessage', 'clearCart']),
      // checkValidCart(itemList) {
      //   let isValid = true
      //   let message = ''
      //
      //   if (!itemList) {
      //     isValid = false
      //     message = 'Your cart is empty!'
      //   } else {
      //     this.cartids = []
      //     itemList.map(item => {
      //       for (let prodIdx = 0; prodIdx < itemList.length; prodIdx++) {
      //         // this.cartids.push(itemList[prodIdx].id)
      //       }
      //     })
      //   }
      //   return {
      //     isValid,
      //     message
      //   }
      // },

      checkout() {
        if (this.isLoggedIn) {
          if (!this.cartItemList || this.cartItemList.length === 0) {
            this.addMessage({
              messageClass: 'warning',
              message: 'Your cart is empty!'
            })
            return
          }
          const {isValid, message} = this.checkValidCart(this.cartItemList)

          if (isValid) {
            // console.log(this.cartids)

            CheckoutService.checkout(this.cartItemList).then(res => {
              console.log(res.data)
              // const { dataa } = res

              if (res.data.success) {
                // this.addMessage({
                //   messageClass: 'success',
                //   message: 'Your order has been successfully processed!'
                // })
                var vm = this
                UIkit.modal.alert('Your order has been successfully processed!', {
                  labels: {
                    ok: 'OK'
                  }
                }).then(function () {
                  vm.clearCart()
                  vm.$router.push('/account/myhistories')
                }, function () {
                  console.log('Rejected.')
                });


              } else {
                this.addMessage({
                  messageClass: 'danger',
                  message: res.data.message
                })
              }


            })
          } else {
            this.addMessage({
              messageClass: 'danger',
              message: message
            })
          }
        } else {
          this.addMessage({
            messageClass: 'warning',
            message: 'Please login to checkout'
          })
        }
      }
    }
  }
</script>
<style scoped>
  .uk-card-body {
    padding: 20px 20px;
  }
</style>
