<template>
  <div class="uk-card-body">
    <div class="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m uk-flex-middle" uk-grid>
      <!-- Product cell-->
      <div>
        <div class="uk-grid-small" uk-grid>
          <div class="uk-width-2-3">
            <div class="tm-ratio tm-ratio-4-3">
              <router-link
                v-if="cartItem.type === 'data'"
                class="tm-media-box"
                :title="cartItem.title"
                tag="a"
                :to="{
              name: 'answer',
              params: {
                answerId: cartItem.id
              }
            }"
              >
                <figure class="tm-media-box-wrap">
                  <vue-plyr data-width="500" data-height="300" v-if="cartItem.datatype == 2">
                    <video poster :src="cartItem.imageurl">
                      <source :src="cartItem.imageurl" type="video/mp4" size="720">
                      <source :src="cartItem.imageurl" type="video/mp4" size="1080">
                      <track
                        kind="captions"
                        label="English"
                        srclang="kr"

                        default
                      >
                    </video>
                  </vue-plyr>
                  <vue-plyr data-width="500" data-height="300" v-else-if="cartItem.datatype == 3">
                    <audio>
                      <source :src="cartItem.imageurl" type="audio/mp3">
                      <source :src="cartItem.imageurl" type="audio/ogg">
                    </audio>
                  </vue-plyr>
                  <img
                    v-else
                    class="lazy"
                    data-width="500"
                    style="width:100%"
                    :data-src="cartItem.imageurl"
                    data-height="300"
                    data-uk-img
                    alt
                  >

                  <!-- <img :src="cartItem.imageurl" :alt="cartItem.title"> -->
                </figure>
              </router-link>
              <router-link
                v-else
                class="tm-media-box"
                :title="cartItem.title"
                tag="a"
                :to="{
              name: 'question',
              params: {
                questionId: cartItem.id
              }
            }"
              >
                <figure class="tm-media-box-wrap">
                  <div class="uk-position-relative uk-visible-toggle uk-light" uk-slideshow>
                    <ul class="uk-slideshow-items">
                      <li v-for="(itemmedia, index) in cartItem.question_datas" :key="index">
                        <vue-plyr
                          data-width="500"
                          data-height="300"
                          v-if="itemmedia.media.mediatype === 'video'"
                        >
                          <video poster :src="`/uploadingDir/`+itemmedia.media.filename">
                            <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                    type="video/mp4" size="720">
                            <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                    type="video/mp4" size="1080">
                            <track
                              kind="captions"
                              label="English"
                              srclang="kr"

                              default
                            >
                          </video>
                        </vue-plyr>
                        <vue-plyr
                          data-width="500"
                          data-height="300"
                          v-else-if="itemmedia.media.mediatype === 'audio'"
                        >
                          <audio>
                            <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                    type="audio/mp3">
                            <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                    type="audio/ogg">
                          </audio>
                        </vue-plyr>

                        <img
                          v-else
                          class="lazy"
                          data-width="500"
                          style="width:100%"
                          :data-src="`/uploadingDir/`+itemmedia.media.filename"
                          data-height="300"
                          data-uk-img
                          alt
                        >

                        <img class="uk-border-circle" width="35" :src="cartItem.organizLogo"
                             :alt="cartItem.organizName"/>
                      </li>
                    </ul>

                    <a
                      class="uk-position-center-left uk-position-small uk-hidden-hover"
                      href="#"
                      uk-slidenav-previous
                      uk-slideshow-item="previous"
                    ></a>
                    <a
                      class="uk-position-center-right uk-position-small uk-hidden-hover"
                      href="#"
                      uk-slidenav-next
                      uk-slideshow-item="next"
                    ></a>
                  </div>
                </figure>
              </router-link>
            </div>
          </div>
          <div class="uk-width-expand">
            <router-link
              v-if="cartItem.type === 'data'"
              class="uk-link-heading"
              :title="cartItem.title"
              tag="a"
              :to="{
              name: 'answer',
              params: {
                answerId: cartItem.id
              }
            }"
            >{{ cartItem.title }}
            </router-link>
            <router-link
              v-else
              class="uk-link-heading"
              :title="cartItem.title"
              tag="a"
              :to="{
              name: 'question',
              params: {
                questionId: cartItem.id
              }
            }"
            ><span v-html="shortDescription"></span>
            </router-link>

            <div class="uk-text-meta">{{ cartItem.description | shortDescription}}</div>
          </div>
        </div>
      </div>
      <!-- Other cells-->
      <div>
        <div
          class="uk-grid-small uk-child-width-1-1 uk-child-width-expand@s uk-text-center"
          uk-grid
        >
          <div>
            <div class="uk-text-muted uk-hidden@m">Price</div>
            <div>
<!--              {{ cartItem.price|currency }}-->
              {{ cartItem.price }}
              <span
                class="uk-button uk-button-small uk-button-primary"
              >P</span>
            </div>
          </div>
          <div  class="tm-cart-quantity-column">
            <a onclick="increment(-1, 'product-1')" uk-icon="icon: minus; ratio: .75"></a>
            <input
              class="uk-input tm-quantity-input"
              :value="cartItem.quantity"
              @input="updateQuantity"
              min="0"
              id="product-1"
              type="number"
              maxlength="3"
            >
            <a onclick="increment(+1, 'product-1')" uk-icon="icon: plus; ratio: .75"></a>
          </div>
          <!--<div>-->
          <!--<div hidden class="uk-text-muted uk-hidden@m">Sum</div>-->
          <!--<div hidden>${{ subtotal }}</div>-->
          <!--</div>-->
          <div class="uk-width-auto@s">
            <a @click="removeItem" class="uk-text-danger" uk-tooltip="Remove">
              <span uk-icon="close"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import {mapActions} from 'vuex'

  export default {
    props: ['cartItem'],
    computed: {
      subtotal() {
        return this.cartItem.quantity * this.cartItem.price
      },
      shortDescription() {
        let value = ""
        if (this.cartItem.type !== 'data') {
          value = this.cartItem.question
        } else {
          value = this.cartItem.description
        }
        if (value && value.length > 100) {
          return value.substring(0, 100) + '...'
        } else {
          return value
        }


      }
    },
    methods: {
      ...mapActions(['updateCart', 'removeItemInCart']),
      removeItem() {
        const vm = this
        this.removeItemInCart({
          item: vm.cartItem
        })
      },
      updateQuantity(event) {
        const vm = this
        this.updateCart({
          item: vm.cartItem,
          quantity: parseInt(event.target.value),
          isAdd: false
        })
      }
    },
    filters: {
      shortDescription(value) {
        if (value && value.length > 100) {
          return value.substring(0, 100) + '...'
        } else {
          return value
        }
      }
    }
  }
</script>
